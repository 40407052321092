#services {
  width: 100%;
  height: 100vh;
  padding: $p;
  background-color: $color5;
  color: $color4;
  overflow: hidden;
  > h2 {
    font: 700 2.5rem $text2;
    width: fit-content;
    border-bottom: 1px solid $color4;
    padding: 0.5rem;
    margin: auto;
  }

  > section {
    display: grid;
    margin: 3rem;
    grid-template-columns: repeat(3, 1fr);
    height: 100%;
    gap: 2rem;
    > * {
      width: 100%;
      height: 100%;
      background-color: $color4;
      color: $color6;
      display: flex;
      flex-direction: column;
      transition: all 0.3s;
      align-items: center;
      justify-content: center;
      &:hover {
        box-shadow: 0 0 5px 15px $color1;
      }

      > svg {
        color: $color5;
        font-size: 3rem;
      }
      > span {
        font: 700 1rem $text2;
        letter-spacing: 3px;
        margin: 1rem;
      }
    }

    .serviceBox1 {
      grid-column: 1 / 2;
      grid-row: 1/3;
      background-color: $color1;

      > h3 {
        font: 100 5rem $text1;
      }
      > p {
        font: 700 1.2rem $text2;
        letter-spacing: 3px;
      }
    }
    .serviceBox4 {
      grid-column: 2/4;
    }
  }
}
