#home {
  width: 100%;
  height: 90vh;
  display: flex;

  > section {
    width: 100%;

    &:first-of-type {
      padding-left: $p;

      > div {
        border-top: 1px solid $color6_2;
        height: 100%;
        padding-top: 6rem;
        > h1 {
          font: 900 4rem $text1;
        }

        .typewriterpara {
          margin: 20px 0;
          height: 20px;
          letter-spacing: 5px;
        }

        > div {
          display: flex;
          margin: 5rem 0;
          width: 70%;
          gap: 4rem;
          align-items: center;
          > a {
            &:first-of-type {
              background-color: $color3;
              color: $color1;
              padding: 1rem 2.5rem;
              transition: all 0.3s;
              &:hover {
                background-color: $color3_1;
              }
            }
            &:last-of-type {
              font: 600 1.2rem $text2;
              color: $color5;
              display: flex;
              align-items: center;
            }
          }
        }

        aside {
          display: flex;
          justify-content: space-between;
          width: 70%;
        }

        article {
          margin: 2rem 0;
          width: 10rem;
          color: $color5;
          position: relative;

          &::after {
            content: "";
            background-color: $color4;
            width: 100%;
            height: 5rem;
            border-radius: 50px;
            position: absolute;
            top: 20%;
            left: 20%;
            z-index: -1;
          }
          > p {
            font: 900 2rem $text1;
          }
          > span {
            font: 500 1rem $text2;
          }
        }

        article[data-special] {
          width: 16rem;
        }
      }
    }

    &:last-of-type {
      border-top: 1px solid $color6_2;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      overflow-x: hidden;

      &::after {
        content: "";
        width: 40vmax;
        height: 40vmax;
        background-color: $color3;
        position: absolute;
        z-index: -1;
        border-radius: 50%;
      }

      > img {
        width: 40vmax;
        object-fit: contain;
        border-radius: 20%;
        height: 40vmax;
      }
    }
  }

  > svg {
    position: absolute;
    font-size: 4rem;
    color: $color5;
    left: 50%;
    bottom: 5%;
    transform: translateX(-50%);
    animation: animateSvg 0.7s infinite linear alternate;
  }
}
